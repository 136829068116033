<template>
  <div class="recognition-page-Two">
    <RecognitionImgPage :backgroundImage="backgroundImage" :wordObj="wordObj" :rightImg="rightImg" :changeBgImg="changeBgImg"/>
  </div>
</template>

<script>
import RecognitionImgPage from "@/components/Course/CoursePage/RecognitionImgPage";
export default {
  data() {
    return {
      backgroundImage: require("@/assets/img/16-GAMES/G68-structure-card/lesson-02-zuoyou-1.svg"),
      changeBgImg: require("@/assets/img/16-GAMES/G68-structure-card/lesson-02-zuoyou-2.svg"),
      rightImg:require("@/assets/img/16-GAMES/G68-structure-card/lesson-02-zuoyou.svg"),
      wordObj: {
        pinyin: "zuǒyòu jiégòu",
        hanzi: "左右结构",
        songUrl: require("@/assets/audio/a-zh/h/he2.mp3"),

      },
    };
  },
  components: {
    RecognitionImgPage,
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-Two {
  width: 100%;
  height: 100%;
}
</style>