<template>
  <div class="game-container">
    <PhoneGame
      :answerList="answerList"
    />
  </div>
</template>

<script>
import PhoneGame from "@/components/Course/GamePage/PhoneGame";
export default {
  data() {
    return {
      answerList: [
        {
          answerInfo:"好吃",
          answerPin:"hǎochī",
          audioSrc: require("@/assets/audio/a-zh/a/a1.mp3"),
          rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
          allImgList: [
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              id:1,
              pinyin:"hǎo",
              hanzi:"好",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-done.svg`),
              id:2,
              pinyin:"bù",
              hanzi:"不",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              id:3,
              pinyin:"chī",
              hanzi:"吃",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              id:4,
              pinyin:"yào",
              hanzi:"要",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-done.svg`),
              id:5,
              pinyin:"huà",
              hanzi:"话",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-done.svg`),
              id:6,
              pinyin:"qi",
              hanzi:"气",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              id:7,
              pinyin:"shuō",
              hanzi:"说",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            },
          ],
        },
        {
          answerInfo:"不要",
          answerPin:"búyào",
          audioSrc: require("@/assets/audio/a-zh/a/a1.mp3"),
          rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/keyi.svg`),
          allImgList: [
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              id:1,
              pinyin:"hǎo",
              hanzi:"好",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-done.svg`),
              id:2,
              pinyin:"bù",
              hanzi:"不",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              id:3,
              pinyin:"chī",
              hanzi:"吃",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              id:4,
              pinyin:"yào",
              hanzi:"要",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-done.svg`),
              id:5,
              pinyin:"huà",
              hanzi:"话",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-done.svg`),
              id:6,
              pinyin:"qi",
              hanzi:"气",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              id:7,
              pinyin:"shuō",
              hanzi:"说",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            },
          ],
        },
        {
          answerInfo:"说话",
          answerPin:"shuōhuà",
          audioSrc: require("@/assets/audio/a-zh/a/a1.mp3"),
          rightImg:require(`@/assets/img/12-Vocabulary/L2/lesson-03-duibuqi.svg`),
          allImgList: [
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              id:1,
              pinyin:"hǎo",
              hanzi:"好",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-done.svg`),
              id:2,
              pinyin:"bù",
              hanzi:"不",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              id:3,
              pinyin:"chī",
              hanzi:"吃",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-yellow.svg`),
              id:4,
              pinyin:"yào",
              hanzi:"要",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-done.svg`),
              id:5,
              pinyin:"huà",
              hanzi:"话",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-done.svg`),
              id:6,
              pinyin:"qi",
              hanzi:"气",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-yellow.svg`),
              id:7,
              pinyin:"shuō",
              hanzi:"说",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            },
          ],
        },
      ],
    };
  },
 
  components: {
    PhoneGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>

