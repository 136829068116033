<template>
  <div class="game-container">
    <NumberClickGame :ObjectList="ObjectList" :backImg="backImg"/>
  </div>
</template>

<script>
import NumberClickGame from "@/components/Course/GamePage/NumberClickGame";
export default {
  data() {
    return {
      backImg:{
        background:require("@/assets/img/16-GAMES/G28-click-reveal/background.svg")
      }
    };
  },
  computed: {
    ObjectList() {
      let objArr = [];
      for (let i = 1; i <= 5; i++) {
        let ele = {
          id: i,
          image1: require(`@/assets/img/16-GAMES/G28-click-reveal/${i}.svg`),
          image2: require(`@/assets/img/16-GAMES/G28-click-reveal/${i}-chinese.svg`),
        };
        objArr.push(ele);
      }
      return objArr;
    },
  },
  components: {
    NumberClickGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>