<template>
  <div class="game-container">
    <ChooseStructureGame :optionList="optionList" :imgList="imgList" :titleInfo="titleInfo" />
  </div>
</template>

<script>
import ChooseStructureGame from "@/components/Course/GamePage/ChooseStructureGame";
export default {
  data() {
    return {
      titleInfo:{
        pinyin:"zuǒyòu jiégòu",
        hanzi:"左右结构"
      },
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-ni.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-hao.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-ma.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-gou.svg"),
        },
       {
          id: 5,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-hen.svg"),
        },
      ],
      imgList: [
        [{
          pinying: "jīn ",
          hanzi: "今",
        },
        {
          pinying: "jǐ ",
          hanzi: "几",
        },
          {
          id: 1,
          pinying: "shuō ",
          hanzi: "说",
        },
        ],
        [{
          id: 2,
          pinying: "huà ",
          hanzi: "话",
        },
        {
          pinying: "niú ",
          hanzi: "牛",
        },
        {
          pinying: "mǐ ",
          hanzi: "米",
        },
        ],
        [{
          pinying: "tiáo ",
          hanzi: "条",
        },
          {
          id: 3,
          pinying: "mā ",
          hanzi: "妈",
        },
        {
          pinying: "zhēn ",
          hanzi: "真",
        },
        ],
        [{
          id: 4,
          pinying: "chī ",
          hanzi: "吃",
        },
        {
          pinying: "zhǎng ",
          hanzi: "长",
        },
        {
          pinying: "duō ",
          hanzi: "多",
        },
        ],
        [{
          id: 5,
          pinying: "dòng ",
          hanzi: "动",
        },
        {
          pinying: "jiàn ",
          hanzi: "见",
        },
        {
          pinying: "gāo",
          hanzi: "高",
        },
        ],
      ],
    };
  },
  components: {
    ChooseStructureGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>