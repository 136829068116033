import { render, staticRenderFns } from "./13_RecognitionPageTwo.vue?vue&type=template&id=5c633f24&scoped=true"
import script from "./13_RecognitionPageTwo.vue?vue&type=script&lang=js"
export * from "./13_RecognitionPageTwo.vue?vue&type=script&lang=js"
import style0 from "./13_RecognitionPageTwo.vue?vue&type=style&index=0&id=5c633f24&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c633f24",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/nihaokidsNew-front_BmM9/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c633f24')) {
      api.createRecord('5c633f24', component.options)
    } else {
      api.reload('5c633f24', component.options)
    }
    module.hot.accept("./13_RecognitionPageTwo.vue?vue&type=template&id=5c633f24&scoped=true", function () {
      api.rerender('5c633f24', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Course/Level2/Chapter1/Lesson6/13_RecognitionPageTwo.vue"
export default component.exports