<template>
  <div class="rank-map-page-one">
    <RankMapPage :lesson="lessonNum" :position="positionNum" :chapterNum="chapter"  />
  </div>
</template>

<script>
import RankMapPage from "@/components/Course/CoursePage/RankMapPage";
export default {
  data() {
    return {
      lessonNum: 6, // course lesson
      positionNum: 0, // 0: start  1: end
      chapter: 1,
    };
  },
  components: {
    RankMapPage,
  },
};
</script>

<style lang="scss" scoped>
.rank-map-page-one {
  width: 100%;
  height: 100%;
}
</style>